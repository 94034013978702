<template>
  <div class="body_">
    <my-fram>
      <div slot="default" class="content">
       <div class="top">
         <div class="top_left">当前位置：政策法规</div>
         <div class="top_right">
           <div class="button" @click="goHome">返回</div>
         </div>
       </div>
       <div class="foot">
         <div class="one">
           <el-row class="ct" v-for="(item,index) in tableData">
             <el-col :span="16" class="ct_1"><span style="cursor: pointer" @click="getDetai(item.id)" >{{item.title}}</span></el-col>
             <el-col :span="4" class="ct_2">[相关解读]</el-col>
             <el-col :span="4" class="ct_3" >{{$moment(item.createTime).format('YYYY-MM-DD')}}</el-col>
           </el-row>
         </div>
       </div>
      </div>
    </my-fram>
  </div>
</template>

<script>
import MyFram from '@/components/myFram'
export default {
  name: 'Palr',
  components: { MyFram },
  data () {
    return {
      url:this.$Config.base_server,
      tableData:[]
    }
  },
  mounted() {
    this.$axios.post(this.url+'/Queryzcfg').then((res)=>{
      if (res.data.resp_code=='0'){
        this.tableData=res.data.datas;
      }else{
        this.$message.error('对不起,查询出错了');
      }
    })
  },
  methods: {
    getDetai (noitfyId) {
      this.$router.push({ path: '/plarDetail',query: {noitfyId:noitfyId} })
    },
    goHome () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style scoped  lang="stylus">
.body_{
  height: 100%;
  width: 100%;
}
.content{
  height 100%
  width 100%
  display: flex;
  flex-direction: column;
  .top{
    width 100%
    height 15%
    display flex
    flex-direction row
    .top_left{
      width 65%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction column
      justify-content center
      justify-items center
      color #FFFFFF
    }
    .top_right{
      width 35%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction row
      justify-content right
      align-items center
      .button{
        width 5rem
        height 2.5rem
        line-height 2.4rem
        text-align center
        background-image linear-gradient(to bottom, #BDE4DD,#06B198)
        border-radius: .3rem;
        font-size 1.2rem
        color #fff
        margin-right 1rem
        cursor pointer
      }
    }
  }
  .foot{
    width 100%
    height 85%
    .one{
      width: 100%;
      height: 100%;
      background #DBEEEC
      display flex
      flex-direction column
      border-radius: 1rem;
      border: 1px solid #82D5FF80 ;
      padding 1rem 1rem
      overflow hidden
      overflow-y auto
      .ct{
        height calc(100% / 7)
        text-indent 2rem
        font-size: 28px;
        color: #333333;
        border-bottom 1px solid rgba(130,213,255,0.314)
        display flex
        flex-direction row
        align-items center
        .ct_2{
          color: #009B84;
        }
        .ct_3{
          color: #333333;
          opacity 0.6
        }
      }
    }
  }
}
</style>
